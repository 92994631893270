<!--<div class='Setting'></div>-->
<!--    月结设置-->

<template>
    <div class="Setting" style="background-color: #f2f2f2; margin-top: 8px">
        <el-card shadow="never" style="margin-top: 8px">
            <el-form @submit.native.prevent :inline="true" style="text-align: left" v-model="form" size="small">
                <el-row>
                    <ef-dept-type-and-dept-select @selectDeptType="setDeptType" @selectDept="setDept" />
                </el-row>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" @click="search" size="small" v-if="hasPrivilege('menu.report.setting.open')"
                >查询</el-button
            >
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-table
                id="printMe"
                border
                stripe
                style="width: 100%"
                :data="tableData"
                size="mini"
                :highlight-current-row="true"
                max-height="450"
            >
                <el-table-column label="机构" min-width="180" prop="deptCode" />
                <el-table-column label="一月" width="90" prop="january" />
                <el-table-column label="二月" width="90" prop="february" />
                <el-table-column label="三月" width="90" prop="march" />
                <el-table-column label="四月" width="90" prop="april" />
                <el-table-column label="五月" width="90" prop="may" />
                <el-table-column label="六月" width="90" prop="june" />
                <el-table-column label="七月" width="90" prop="july" />
                <el-table-column label="八月" width="90" prop="august" />
                <el-table-column label="九月" width="90" prop="september" />
                <el-table-column label="十月" width="90" prop="october" />
                <el-table-column label="十一月" width="90" prop="november" />
                <el-table-column label="十二月" width="90" prop="december" />

                <el-table-column label="操作" width="80" header-align="center">
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            type="warning"
                            @click="handleEditMonthSettle(scope.row)"
                            v-if="hasPrivilege('menu.report.setting.edit')"
                            >编辑</el-button
                        >
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                :page-sizes="page.pageSizes"
                :page-size="form.limit"
                :layout="page.PageStyle"
                :total="page.total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                @prev-click="handlePrevClick"
                @next-click="handleNextClick"
            />
        </el-card>
        <el-dialog title="设置" :visible.sync="showEdit" top="60px">
            <el-card shadow="never">
                <el-form
                    @submit.native.prevent
                    :inline="true"
                    style="text-align: right"
                    ref="editMonth"
                    :model="editMonth"
                    :rules="rules"
                    label-position="center"
                >
                    <el-row>
                        <el-col :span="4">
                            <el-form-item label="一月" prop="january">
                                <el-input
                                    type="number"
                                    v-model.number="editMonth.january"
                                    :readonly="readonly"
                                    min="1"
                                    max="31"
                                    step="1"
                                    size="small"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="二月" prop="february">
                                <el-input
                                    type="number"
                                    v-model.number="editMonth.february"
                                    :readonly="readonly"
                                    min="1"
                                    max="28"
                                    step="1"
                                    size="small"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="三月" prop="march">
                                <el-input
                                    type="number"
                                    v-model.number="editMonth.march"
                                    :readonly="readonly"
                                    min="1"
                                    max="31"
                                    step="1"
                                    size="small"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="四月" prop="april">
                                <el-input
                                    type="number"
                                    v-model.number="editMonth.april"
                                    :readonly="readonly"
                                    min="1"
                                    max="30"
                                    step="1"
                                    size="small"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="五月" prop="may">
                                <el-input
                                    type="number"
                                    v-model.number="editMonth.may"
                                    :readonly="readonly"
                                    min="1"
                                    max="31"
                                    step="1"
                                    size="small"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="六月" prop="june">
                                <el-input
                                    type="number"
                                    v-model.number="editMonth.june"
                                    :readonly="readonly"
                                    min="1"
                                    max="30"
                                    step="1"
                                    size="small"
                                />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="4">
                            <el-form-item label="七月" prop="july">
                                <el-input
                                    type="number"
                                    v-model.number="editMonth.july"
                                    :readonly="readonly"
                                    min="1"
                                    max="31"
                                    step="1"
                                    size="small"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="八月" prop="august">
                                <el-input
                                    type="number"
                                    v-model.number="editMonth.august"
                                    :readonly="readonly"
                                    min="1"
                                    max="31"
                                    step="1"
                                    size="small"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="九月" prop="september">
                                <el-input
                                    type="number"
                                    v-model.number="editMonth.september"
                                    :readonly="readonly"
                                    min="1"
                                    max="30"
                                    step="1"
                                    size="small"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="十月" prop="october">
                                <el-input
                                    type="number"
                                    v-model.number="editMonth.october"
                                    :readonly="readonly"
                                    min="1"
                                    max="31"
                                    step="1"
                                    size="small"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="十一月" prop="november">
                                <el-input
                                    type="number"
                                    v-model.number="editMonth.november"
                                    :readonly="readonly"
                                    min="1"
                                    max="30"
                                    step="1"
                                    size="small"
                                />
                            </el-form-item>
                        </el-col>
                        <el-col :span="4">
                            <el-form-item label="十二月" prop="december">
                                <el-input
                                    type="number"
                                    v-model.number="editMonth.december"
                                    :readonly="readonly"
                                    min="1"
                                    max="31"
                                    step="1"
                                    size="small"
                                />
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </el-card>
            <div slot="footer">
                <el-button @click="showEdit = false" size="small">取 消</el-button>
                <el-button type="primary" @click="onEditConfirm" size="small">确 定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import Util from 'js/Util';
import UrlUtils from 'js/UrlUtils';
import EfDeptTypeAndDeptSelect from 'components/EfDeptTypeAndDeptSelect';

export default {
    name: 'Setting',
    components: { EfDeptTypeAndDeptSelect },
    data() {
        const singleRule = function (max) {
            return [
                { type: 'integer', message: '日期错误', trigger: 'blur' },
                { type: 'number', min: 1, message: '日期错误', trigger: 'blur' },
                { type: 'number', max: max, message: '日期错误', trigger: 'blur' },
                { required: true, message: '日期错误', trigger: 'blur' },
            ];
        };
        return {
            form: {
                deptCode: '',
                page: 1,
                limit: Util.Limit,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            tableData: [],
            url: {
                page: '/report/monthSettle/setting/page',
                edit: '/report/monthSettle/setting/patch_c',
            },
            current: {
                typeIdx: 3,
            },
            meta: {
                types: [],
            },

            //以下是编辑相关

            showEdit: false,
            editMonth: {},
            readonly: false,
            rules: {
                january: singleRule(31),
                february: singleRule(29),
                march: singleRule(31),
                april: singleRule(30),
                may: singleRule(31),
                june: singleRule(30),
                july: singleRule(31),
                august: singleRule(31),
                september: singleRule(30),
                october: singleRule(31),
                november: singleRule(30),
                december: singleRule(31),
            },
        };
    },
    mounted() {
        this.handleQuery();
    },
    methods: {
        setDeptType(deptType) {
            this.form.deptType = deptType;
        },
        setDept(deptCode, deptName) {
            this.form.deptCode = deptCode;
            this.form.deptName = deptName;
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        search() {
            if (this.form.deptCode == '') {
                this.$message.error('请您选择相应机构!');
                return;
            }
            this.handleQuery();
        },
        handleExport() {},

        handleSelectType(typeIndex) {
            this.form.deptCode = this.meta.types[typeIndex].children[0].code;
        },
        handleEditMonthSettle(row) {
            this.editMonth = JSON.parse(JSON.stringify(row));
            this.showEdit = true;
        },
        onEditConfirm() {
            const p = {
                code: this.editMonth.code,
                january: this.editMonth.january,
                february: this.editMonth.february,
                march: this.editMonth.march,
                april: this.editMonth.april,
                may: this.editMonth.may,
                june: this.editMonth.june,
                july: this.editMonth.july,
                august: this.editMonth.august,
                september: this.editMonth.september,
                october: this.editMonth.october,
                november: this.editMonth.november,
                december: this.editMonth.december,
            };

            this.$refs['editMonth'].validate((valid) => {
                if (!valid) return;
                UrlUtils.PatchRemote(this, this.url.edit + '/' + p.code, p, null, (rsp) => {
                    this.$message.success('保存成功');
                    this.showEdit = false;
                    this.handleQuery();
                });
            });
        },
    },
};
</script>

<style scoped>
.Setting .el-form-item {
    margin-bottom: 0;
}
</style>
